import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"
import IconWatch from "../icons/icon-watch"
import IconCalendar from "../icons/icon-calendar"
import FacebookFollow from "../components/facebook-follow"
import BlogForm from "../components/blog-form"


export default function BlogPage({ data }) {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <Layout>
      <SEO title="Blog" description="Concise web design resources for businesses to make smart decisions for their brand's online strategy."/>
      <div className="cols-1-1 blog-page-introduction">
          <div>
            <h3 class="h3-on-h1">Blog</h3>
            <h1>Web Design Resources for Entrepreneurs and Business Owners.</h1>
          </div>
          <div>
            <p>Be well-informed about essential web design concepts, ideas, and best practices. Here, you'll only find <strong>concise, jargon-free articles</strong> easily digested in your busy schedule. This blog's mission is to help you <strong>make smart decisions</strong> for your website and online strategy.</p>
          </div>
      </div>
      <div className="blog-roll section">
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <div className="blog-card-wrapper" key={post.id}>
                <Link to={post.frontmatter.path}>
                  <div className="blog-card">
                    <div>
                    {post.frontmatter.cover && (
                      <Image
                        fluid={post.frontmatter.cover.childImageSharp.fluid}
                        alt="Banner Image"
                      />
                    )}
                    </div>
                    <div>
                      <h3>{post.frontmatter.title}</h3>
                      <ul className="has-inline-svg small-text">
                        <li>
                          <IconWatch/>
                          <p>{post.timeToRead} min. read</p>
                        </li>
                        <li>
                          <IconCalendar/>
                          <p>{post.frontmatter.date}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Link> 
              </div>
            )
          })
        }
      </div>
      <FacebookFollow/>
      <div className="cols-1-1 section">
        <div>
          <h1>Request an Article.</h1>
          <p><strong>Don't see a topic you'd like to learn more about?</strong></p>
          <p>You're in luck. I want to do my best to help entrepreneurs and business owners figure out the web design world.</p>
          <p>Request for an article and I just might write one to help you out! You don't have to pay anything.</p>
        </div>
        <BlogForm/>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          timeToRead
          frontmatter {
            title
            date(formatString: "DD MMM 'YY")
            path
            description
            cover {
              childImageSharp {
                fluid (maxWidth: 376, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`