import React from "react"


const FacebookFollow = () => (
    <div className="section-highlight">
        <div className="cols-1 section">
            <h1>Stay updated.</h1>
            <p>Follow me on Facebook and get notified whenever a new article is out.</p>
            <div id="facebook-follow">
                <a href="https://www.facebook.com/nadzimwebdesign" target="_blank" rel="noopener noreferrer">Go to <strong>Nadzim.com</strong> on Facebook</a>
                <p className="small-text"><em>Opens in a new tab.</em></p>
            </div>
        </div>
    </div>
)

export default FacebookFollow